<template>
    <div class="services" href="#services">
        <div class="service">
            <header>BASIC CLEANING</header>
            <ul>
                <li>Dusting</li>
                <li>Vaccuming</li>
                <li>Mopping</li>
                <li>Bathrooms</li>
                <li>Countertops</li>
                <li>Outsides of Appliances</li>
                <li>Changing Sheets/Making Beds</li>
            </ul>
        </div>
        <div class="service">
            <header>DEEP CLEANING</header>
            <ul>
                <li>Basic Cleaning</li>
                <li>Baseboards</li>
                <li>Cupboards</li>
                <li>Ceiling Fans</li>
                <li>Windows</li>
                <li>Blinds</li>
                <li>Inside of Appliances</li>
            </ul>
        </div>
        <div class="service">
            <header>CUSTOM CLEANING</header>
            <p>The listed services note a small portion of our frequently used services. If you need something that is not listed just ask!</p>
            <p>Below you can fill out a quote and include any custom items that are not listed here and we will reach out with the options available to you.</p>
        </div>
    </div>
</template>

<script setup>

</script>

<style>
.services {
    height: 60vh;
    background-color:#1d1d1d;
    display: flex;
    flex-direction:row;
    justify-content: space-between;
    padding-left: 4rem;
    padding-right: 4rem;
    padding-top: 1rem;
    width: 95vw;
    margin-left: -.5rem;
    
}

.service {
    color:#EEEEEE;
    padding: 30px;
    width: 26%;
    height: 25rem;
    background-color: #2d2d2d;
    border-radius: 10px;
}

.service:hover {
    box-shadow: 0 8px 16px 0 rgb(0, 0, 0, .9);
    border-color:#888888;
    border-width:1px;
    border-style:solid;
}

header {
    font-size: 20px;

}

ul {
    list-style-type: none;
    padding: 0;
    padding-top: 20px;
    margin: 0;
}

li {
    padding: 10px;
    margin-bottom: 10px;
    border-radius: 5px;
    font-size: 18px;
    font-weight: medium;
}

p {
    padding-top: 30px;
    line-height: 30px;

}


@media screen and (max-width: 800px) {
	.services {
		flex-direction: column;
        justify-content: center;
        padding-top: 2rem;
        align-content:center;
        height:100%;
	}
    .service{
        width: 60%;
        margin-bottom: 3rem;

    }
}
</style>