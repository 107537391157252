<template>
    <div class="navbar">
        <img src="../assets/mmcleaning_logo.png" class="logo"/>
        <div class="navitems"> 
            <!-- <button class="navitem" @click="serviceClick">Services</button>
            <button class="navitem">Get a Quote</button> -->
        </div>  
    </div>
  </template>
  
<script>
// export default {
//     setup() {
//         function serviceClick() {
//             window.location.hash = '#services'
//         }        
  
//       return {
//         serviceClick
//       }
//     }
//   }

</script>
  
  <!-- Add "scoped" attribute to limit CSS to this component only -->
  <style scoped>
  
  .header {
    font-size: 2.5rem;
    text-align: center;
    color:#d3d3d3;
    margin:0 auto;
    display: inline-block;
  }
  .logo {
    width: 3rem;
    height: 2.5rem;
    float:left;
    padding-right: 4rem;
  }
  
  /* Navbar */
  .navbar {
    position:fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 4rem;
    background-color: #1d1d1d;
    padding-left: 30px;
    padding-top: 1rem;
    display:flex;
    /* box-shadow: 0px 10px 5px 0px rgba(0,0,0,0.75); */
  }
  
  /* Navbar Items */
  .navitem {
    float: left;
    padding: 10px;
    padding-top: 10px;
    color: #d3d3d3;
    background-color: #1d1d1d;
    text-align: center;
    font-size: 16px;
    border: none;
    border-radius: 4px;
  }
  
  .navitem:hover {
    color: #FFFFFF;
    background-color: #2d2d2d;
  }
  </style>
  