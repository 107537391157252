<template>
    <div class="container">
        <img src="../assets/mmcleaning.svg" />
        <header>M&M Cleaning</header>
    </div>
</template>

<script setup>
</script>

<style scoped>
@import url('https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100;0,200;0,300;0,500;1,500&display=swap');
    .container {
        width: 120vw;
        height: 18rem;
        background-color: #1d1d1d;
        display: block;
        margin-top: 4rem;
    }

    img {
        padding-top: 1rem;
        padding-right: 20vw;
    }


    header {
        color: #f3f3f3;
        font-size: 3rem;
        font-family: 'Montserrat', sans-serif;
        padding-right: 20vw;
    }
</style>