<template>
<div class="container">
    <form>
      <div class="input">
        <label for="name">Name:</label>
        <input type="text" id="name" v-model="name" />
      </div>
      <div class="input">
        <label for="city">City:</label>
        <input type="text" id="city" v-model="city" />
      </div>
      <div class="input">
        <label for="sqft">Sq Ft:</label>
        <input type="number" id="sqft" v-model="sqft" />
      </div>
      <div class="input">
        <label for="email">Email:</label>
        <input type="email" id="email" v-model="email" />
      </div>
      <div class="input" >
        <label for="bedrooms"># Bedrooms:</label>
        <input type="number" id="bedrooms" v-model="bedrooms" />
      </div>
      <div class="input">
        <label for="bathrooms"># Bathrooms:</label>
        <input type="number" id="bathrooms" v-model="bathrooms" />
      </div>
      <div class="input">
        <label for="phone">Phone:</label>
        <input type="tel" id="phone" v-model="phone" />
      </div>
      <div class="input">
        <label for="startDate">Start Date:</label>
        <input type="date" id="startDate" v-model="startDate" />
      </div>
      <div class="input">
        <label for="description">Description:</label>
        <textarea id="description" v-model="description"></textarea>
      </div>
      <div class="input">
        <button type="button" @click="onSubmit">Submit</button>
      </div>
      <button class="admin">Admin</button>
    </form>
    
</div>
  </template>
  
  <script>
  import { ref } from 'vue'
  import { createClient } from '@supabase/supabase-js'
  import { useToast } from "vue-toastification"
  export default {
    setup() {
      const name = ref('')
      const organization = ref('')
      const city = ref('')
      const sqft = ref('')
      const email = ref('')
      const bedrooms = ref('')
      const bathrooms = ref('')
      const phone = ref('')
      const startDate = ref('')
      const description = ref('')


      const supabaseUrl = "https://aemlhbetfuctmcylhlit.supabase.co"
      const supabaseKey = process.env.VUE_APP_SECRET
      const supabase = createClient(supabaseUrl,  supabaseKey)
      const toast = useToast()
      async function onSubmit(){
        const {error} = await supabase
            .from('quoterequests')
            .insert([
                {
                    name: name.value,
                    email: email.value,
                    city: city.value,
                    cell: phone.value.replace(/\D/g,''),
                    sqft: sqft.value,
                    bedrooms: bedrooms.value,
                    bathrooms: bathrooms.value,
                    date_start: startDate.value,
                    description: description.value
                }
            ]);

        console.log(error)
        if (error == null) {
            toast.success("Quote Submitted")
        }
      }
  
      return {
        name,
        organization,
        city,
        sqft,
        email,
        bedrooms,
        bathrooms,
        phone,
        startDate,
        description,
        onSubmit,
        toast
      }
    }
  }
  </script>

<style>

.container {
    width: 100vw;
    background-color: #1d1d1d;
    margin-left: -.5rem;
    height: 100vh;
}
form {
  display: flex;
  flex-direction: column;
  padding: 10px;
  width: 50%;
  font-size: 20px;
  margin: 0 auto;
}

form div {
    padding-bottom: 15px;

}
label {
    color: #EEEEEE;
    width: 20%;
    text-align: right;
}

input, textarea {
    float: right;
    height: 30px;
    background-color: transparent;
    border-color: #777777;
    border-radius: 3px;
    border-style:solid;
    color: #FFFFFF;
    font-size: 15px;
    padding-left:10px;
}

input:focus, textarea:focus{
    outline:none;
    border-color: #FFFFFF;
}
textarea {
    height: 80px;
}
label+input, label+textarea {
  width: 30%;
  /* Large margin-right to force the next element to the new-line
           and margin-left to create a gutter between the label and input */
  margin: 0 30% 0 4%;
}

button {
    background-color: #2d2d2d;
        color: #FFFFFF;
        padding: 10px 20px;
        border-radius: 5px;
        font-size: 1rem;
        font-weight: bold;
        margin-top: 50px;
}

.admin {
    margin-top: 12rem;
    background-color: #1d1d1d;
    color: #444444;
    border-width: 0;
}

.admin:hover {
    border-width:1px;
}

@media screen and (max-width: 800px) {
	form {
        width: 100vw;
        display: block;
    }

    .input {
        margin-bottom: 15px;
        width: 120%;
    }

    .admin {
        margin-top: 40px;

    }
    
    form+div {
        padding-bottom: 10px;
    }
}
</style>